

    .package-box {
        .package-summary {
            .package-limit{
                font-size: calc(var(--global-font-size) * 2.4);

            }
            .content {
                margin-bottom: calc(var(--global-margin) * 2);
            }
        }
        .package-info-separator {
            margin-top: calc(var(--global-margin) * 1.5);
            margin-bottom: calc(var(--global-margin) * 1.5);

        }
    }

